<template>
  <div class="message-detail">
    <div class="partition-area">
      <h2>{{ utils.isEffectiveCommon(messageDetail.title) }}</h2>
      <div class="message-main">
        <p>{{ utils.isEffectiveCommon(messageDetail.content) }}</p>
      </div>
      <div class="message-footer">
        <span class="mr15">发送时间：{{ utils.isEffectiveCommon(messageDetail.createTime) }}</span>
        <span>发送人：{{ utils.isEffectiveCommon(messageDetail.senderName) }}</span>
      </div>
    </div>
    <van-row class="fixed-btn-bottom" gutter="20">
      <van-button type="info" color="#1373CC" block text="返回" @click="$router.back()" />
    </van-row>
  </div>
</template>

<script>
import { Button, Row } from 'vant'

export default {
  components: {
    [Row.name]: Row,
    [Button.name]: Button
  },
  data () {
    return {
      messageDetail: {}
    }
  },
  created () {
    this.getDetail()
  },
  methods: {
    getDetail () {
      this.api.base.messageDetail(this.$route.query.id).then(result => {
        this.messageDetail = result.data.value
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="less">
.message-detail {
  .partition-area {
    padding: 20px;
  }

  h2 {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin: 30px 0;
  }

  .message-main {
    font-size: 14px;
    margin-bottom: 25px;
  }

  .message-footer {
    font-size: 12px;
    text-align: right;

  }
}
</style>
