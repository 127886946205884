var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "message-detail" },
    [
      _c("div", { staticClass: "partition-area" }, [
        _c("h2", [
          _vm._v(_vm._s(_vm.utils.isEffectiveCommon(_vm.messageDetail.title)))
        ]),
        _c("div", { staticClass: "message-main" }, [
          _c("p", [
            _vm._v(
              _vm._s(_vm.utils.isEffectiveCommon(_vm.messageDetail.content))
            )
          ])
        ]),
        _c("div", { staticClass: "message-footer" }, [
          _c("span", { staticClass: "mr15" }, [
            _vm._v(
              "发送时间：" +
                _vm._s(
                  _vm.utils.isEffectiveCommon(_vm.messageDetail.createTime)
                )
            )
          ]),
          _c("span", [
            _vm._v(
              "发送人：" +
                _vm._s(
                  _vm.utils.isEffectiveCommon(_vm.messageDetail.senderName)
                )
            )
          ])
        ])
      ]),
      _c(
        "van-row",
        { staticClass: "fixed-btn-bottom", attrs: { gutter: "20" } },
        [
          _c("van-button", {
            attrs: { type: "info", color: "#1373CC", block: "", text: "返回" },
            on: {
              click: function($event) {
                return _vm.$router.back()
              }
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }